(function () {
    'use strict';

    angular.module('informaApp')
        .service('AdminUserService', ['BaseApiService', 'ConstantsSvc', AdminUserService]);

    function AdminUserService(BaseApiService, ConstantsSvc) {
        return {
            getUsers: function (params) {
                return BaseApiService.post('admin/users', params)
                .then(function (response) {
                    return mapUsers(response.data.data);
                });
            },
            getUserCount: function (filter) {
                return BaseApiService.post('admin/users/count', filter)
                .then(function (response) {
                    return response.data.data;
                });
            },
            editUser : function (userId, data) {
                return BaseApiService.put('users/' + userId, data);
            },
            getCompanies: function (filter) {
                return BaseApiService.post('admin/users/companies', filter);
            }
        };
    }

    function mapCompanies(companies) {
        return companies.map(function (x) {
            return {
                text: x.companyName,
                value: x.companyName,
                count: x.userCount,
                originalName: x.companyName ? toTitleCase(x.companyName) : x.companyName
            };
        }); 
    }

    function toTitleCase(str) {
        return str.replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
    }

    function mapUsers(users) {
        return users.map(function (x) {
            x.expiresOn = x.expiresOn ? convertToDateWithoutTimezone(x.expiresOn) : null;
            x.last_login = x.last_login ? convertToDateWithoutTimezone(x.last_login) : null;

            return x;
        });
    }

    function convertToDateWithoutTimezone(source) {
        var date = new Date(source);

        var offset = date.getTimezoneOffset();

        if (offset <= 0) {
            return date;
        }

        date.setTime(date.getTime() + offset * 60 * 1000);

        return date;
    }
})();
